.clientCard {
    width: 25rem;
    height: 23rem;
    background: $colorBlack;
    box-shadow: 0px 4px 94px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    @extend %flexWrap;
    align-items: center;
    padding: 2rem;

    .clientName {
        width: 70%;
    }

    .clientPic {
        width: 30%;
    }

    .clientTitle .clientText {
        width: 100%;
    }
}