@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

$font: 'Poppins', sans-serif;
$fontWeight: 800;
$fontWeightResp: 500;
$fontWeightTitle: 700;

$fontSizeHeader: 20px;
$fontSizeButton: 16px;
$fontSizeTextSupHeader: 10px;
$fontSizeTextInfHeader: 14px;
$fontSizeTitle: 2rem;
$fontSizeTitleSection: 30px;
$fontSizeCardTitle: 19px;
$fontSizeTitleResp: 34px;



$colorPrim: #13A3D9;
$colorWhite: #E7E7E7;
$colorBlack: black;
$colorGray: #D2D2D2;
$colorMenu: rgba(134, 134, 134, 0.62);
$colorSidebar: rgba(217, 217, 217, 0.459);
$colorFooterResp: #1B1B1B;
$colorBackCarouTitle: rgba(231, 231, 231, 0.336);
$colorBackProjCartel: rgba(78, 78, 78, 0.651);
$colorTextSection: #B0B0B0;




$mobileScreen: 1000px;