.landing {
    font-family: $font;
    color: $colorWhite;
    @extend %imageBackground;
    @extend %flexColumnCenter;

    .landCarousel {
        width: 80%;

        //modifico la ubicación del caption en el carousel
        .carousel-caption {
            right: 50%;
            left: 5%;
            top: 5rem;
            bottom: auto;
            text-align: start;
            padding-left: 1.25rem;

            @media screen and (max-width: $mobileScreen) {
                display: none;
            }
        }

        .carouText {
            background-color: $colorBackProjCartel;
            border-radius: 22px;

            h1 {
                font-weight: $fontWeightTitle;
                font-size: $fontSizeTitle;
                text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            }
        }
    }

    .textMobile {
        display: none;
        margin: 2rem;

        h2 {
            //font-size: $fontSizeTitleResp;
            font-weight: $fontWeightTitle;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }

        @media screen and (max-width: $mobileScreen) {
            display: unset;
        }
    }

    .landSegments {
        width: 100%;
        display: flex;
        justify-content: space-around;
        margin: 1rem 0;

        .landSeg {
            width: 40%;
            //      color: $colorWhite;

            &:hover {
                @extend %hover110;
            }

            .landSegLeft {
                padding-left: 5rem;

                @media screen and (max-width: $mobileScreen) {
                    padding-left: 0;

                }
            }

            .landSegRight {
                padding-right: 5rem;

                @media screen and (max-width: $mobileScreen) {
                    padding-right: 0;
                    padding-top: 2rem;
                }
            }

            @media screen and (max-width: $mobileScreen) {
                width: 80%;
                flex-wrap: wrap;
                align-content: center;
                margin-left: 2rem;
            }
        }

        @media screen and (max-width: $mobileScreen) {
            flex-direction: column;
            justify-content: center;
        }

    }

    .landServices {
        @extend %flexColumnCenter;
        width: 80%;

        .landServText {
            text-align: center;
            justify-content: center;
            width: 70%;

            .landServTitle {
                @extend %fontTitle;
                margin-bottom: 2rem;
            }

            .landServDescrip {
                font-size: $fontSizeButton;
                margin: 4rem 0;

                @media screen and (max-width: $mobileScreen) {
                    margin-top: 0;
                    margin-bottom: 2rem;
                }
            }

            @media screen and (max-width: $mobileScreen) {
                width: 90%;
            }
        }

        .landServIcons {
            width: 100%;
            @extend %flexWrap;
            column-gap: 10rem;
            row-gap: 5rem;

            @media screen and (max-width: $mobileScreen) {
                row-gap: 1rem;
            }

        }

        @media screen and (max-width: $mobileScreen) {
            margin-top: 3rem;
        }

    }

    .landUs {
        width: 90%;
        margin-bottom: 2rem;

        .usTitle {
            font-weight: $fontWeightTitle;
            text-align: center;
            margin-bottom: 4rem;

            @media screen and (max-width: $mobileScreen) {
                margin-bottom: 2rem;
            }
        }

        .landUsCards {
            @extend %flexWrap;
            column-gap: 10rem;
            row-gap: 5rem;

            @media screen and (max-width: $mobileScreen) {
                row-gap: 1rem;
            }
        }

        @media screen and (max-width: $mobileScreen) {
            margin-top: 3rem;
        }
    }

    .landClients {
        width: 100%;
        background: linear-gradient(180deg, $colorWhite 0%, rgba(231, 231, 231, 0) 100%);
        margin-bottom: 5rem;

        .clientsTitle {
            margin-top: 5rem;
            margin-left: 15rem;
            color: $colorPrim;
            font-weight: $fontWeightTitle;

            @media screen and (max-width: $mobileScreen) {
                margin-top: 2rem;
                margin-left: 2rem;
            }
        }

        .clientsText {
            margin-left: 15rem;
            color: $colorBlack;
            font-weight: $fontWeightResp;
            font-size: $fontSizeHeader;

            @media screen and (max-width: $mobileScreen) {
                margin-left: 2rem;
            }
        }

        .clientsCards {
            width: 100%;
            margin-top: 3rem;
            @extend %flexWrap;
            column-gap:2rem;
            row-gap: 2rem;

            @media screen and (max-width: $mobileScreen) {
                width: 90%;
                margin-top: 2rem;
                row-gap: 2rem;
                margin-left: 1rem;
            }
        }
    }

    @media screen and (max-width: $mobileScreen) {
        padding-top: 7rem;
    }

}