.contact {
    background-color: $colorBlack;
    padding-top: 10rem;
    width: 100%;
    font-family: $font;
    font-weight: $fontWeightTitle;
    color: $colorWhite;
    text-align: center;

    .map {
        width: 100%;
        height: 80rem;
        //position: absolute;
        background-image: url('../../img/sudamerica.png');
        background-size: 38rem;
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: 60%;


        .location1 {
            //position: absolute;
            left: 9.5rem;
            top: 44rem;

            //z-index: 2;
            @media screen and (max-width: $mobileScreen) {
                left: 4.5rem;
                top: 22rem;
            }
        }

        .location2 {
            //position: absolute;
            left: -0.3rem;
            top: 42.5rem;
            z-index: 9 !important;

            //z-index: 2;
            @media screen and (max-width: $mobileScreen) {
                left: 0.5rem;
                top: 21rem;
            }
        }

        .location3 {
            //position: absolute;
            left: -8rem;
            top: 41.5rem;
            z-index:9 !important;

            //z-index: 2;
            @media screen and (max-width: $mobileScreen) {
                left: -3.3rem;
                top: 20.75rem;
            }
        }

        .location4 {
            //position: absolute;
            left: -4rem;
            top: 31.5rem;

            //z-index: 2;
            @media screen and (max-width: $mobileScreen) {
                left: -2.3rem;
                top: 16.75rem;
            }
        }

        .location5 {
            //position: absolute;
            left: -7rem;
            top: 40.5rem;

            //z-index: 2;
            @media screen and (max-width: $mobileScreen) {
                left: -4.3rem;
                top: 19.75rem;
            }
        }

        .locationPin {
            width: 65%;
            z-index: 1;

            @media screen and (max-width: $mobileScreen) {
                width: 100%;
            }
        }

        .tooltip {
            display: inline-block;
            position: relative;
            text-align: left;
            opacity: 1;
            z-index: 1;
            @media screen and (max-width: $mobileScreen) {
                max-width: 40px;
            }
        }

        .tooltip .right {
            min-height: 156px;
            width: 18rem;
            top: 50%;
            left: 100%;
            margin-left: 20px;
            transform: translate(0, -50%);
            padding: 10px 20px;
            color: $colorWhite;
            background-color: $colorBlack;
            font-weight: normal;
            font-size: 13px;
            position: absolute;
            z-index: 1;
            box-sizing: border-box;
            border: 2px solid $colorPrim;
            border-radius: 25px;
            visibility: hidden;
            opacity: 0;
            transition: opacity 0.8s;

            display: grid;
            grid-template-columns: 1fr 3fr;
            grid-template-rows: 1fr 1fr 1fr;
            gap: 3% 2%;
            grid-template-areas:
                ". ."
                ". ."
                ". .";
            align-items: center;

            @media screen and (max-width: $mobileScreen) {
                top: 19rem;
                left: 0px;
                margin-left: 0px;
                width: 100vw;
                position: fixed;
            }
        }

        .tooltip:hover .right {
            visibility: visible;
            opacity: 1;
        }

        .tooltip .right i {
            position: absolute;
            top: 50%;
            right: 100%;
            margin-top: -90px;
            width: 90px;
            height: 180px;
            overflow: hidden;
        }

        .tooltip .right i::after {
            content: '';
            position: absolute;
            width: 90px;
            height: 90px;
            left: 0;
            top: 50%;
            transform: translate(50%, -50%) rotate(-45deg);
            background-color: transparent;
            border: 2px solid $colorPrim;

            @media screen and (max-width: $mobileScreen) {
                display: none;
            }
        }


        @media screen and (max-width: $mobileScreen) {
            background-size: 15rem;
            height: 50rem;
            margin-top: 30px;
        }
    }

    .contactLinks {
        width: 100%;
        height: 8rem;
        display: flex;
        padding-bottom: 2rem;

        .contactItem {
            @extend %contactItem;
            border-right: 1px solid #C2C2C2;

            .contactSocialTitle {
                @media screen and (max-width: $mobileScreen) {
                    display: none;
                }
            }

            .contactSocialIcon:hover {
                @extend %hover110;
            }
            
            @media screen and (max-width: $mobileScreen) {
                width: 90%;
                border-right: none;
                justify-content: center;
                column-gap: 1rem;
                margin-left: 1rem;
                padding-bottom: 1rem;
                //border-bottom: 1px solid #C2C2C2;
            }
        }

        .contactItem2 {
            @extend %contactItem;

            @media screen and (max-width: $mobileScreen) {
                width: 90%;
                border-right: none;
                margin-left: 1rem;
                margin-bottom: 1rem;
            }

        }

        @media screen and (max-width: $mobileScreen) {
            flex-direction: column-reverse;
        }

    }

    @media screen and (max-width: $mobileScreen) {
        padding-top: 5rem;
    }
}