.landArrow {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin: 1rem 0;


    @media screen and (max-width: $mobileScreen) {
        display: none;
    }
}