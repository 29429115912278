.sidebarBtn {
    cursor: pointer;
    position: fixed;
    width: 3rem;
    height: 15rem;
    left: calc(100% - 3rem);
    top: calc(50% - 7rem);

    &:hover {
        @extend %hover110;
    }
}

.hide {
    display: none;
}

//ubico el sidebar a mitad de pantalla
.offcanvas.offcanvas-end {
    top: calc(50% - 17rem);
    width: 20rem;
}

.sidebarCont {
    font-family: $font;
    position: fixed;
    height: 35rem;
    background: $colorBackProjCartel;
    //border: 1px solid $colorWhite;
    border-radius: 50px 0px 0px 50px;

    .sidebarText {
        color: $colorWhite;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);

        .sidebarLink {

            &:hover {
                color: $colorPrim;

            }
        }
    }
}