.footer {
    height: 24rem;
    background-color: $colorPrim;
    margin: none;
    font-family: $font;


    .groupFooter {
        padding: 2.5rem;
        display: grid;
        grid-template-columns: 2fr 1fr 1.5fr 1.5fr;
        grid-template-rows: 1fr;
        gap: 0px 0px;
        grid-template-areas: '. . . .';

        .imgFooter:hover {
            @extend %hover110;
        }

        h6 {
            margin-left: 3rem;
            @extend %mediaNone;
        }

        .linksFooter {
            padding-top: 3rem;
            font-weight: $fontWeight;

            .linkFoot {
                margin: 0.5rem 0;

                &:hover {
                    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
                }
            }

            @extend %mediaNone;
        }

        .contactFooter {
            padding-top: 3rem;

            .contactFooterTitle {
                font-weight: bolder;
                margin-bottom: 1.5rem;
            }

            @extend %mediaNone;
        }

        .socialFooter {
            //display: flex;
            justify-content: flex-end;
            align-items: flex-end;

            @extend %mediaNone;

            .footerSocialIcon:hover {
                @extend %hover110;
            }

            @media screen and (min-width: $mobileScreen) {
                display: flex;
            }
        }

        @media screen and (max-width: $mobileScreen) {
            display: flex;
            justify-content: center;
            padding: 0;
        }

    }

    .firmFooter {
        border-top: 0.8px solid $colorGray;
        text-align: center;
        height: 4rem;
        padding-top: 1.2rem;

        @media screen and (max-width: $mobileScreen) {
            height: 4rem;
            color: $colorWhite;
        }

    }

    @media screen and (max-width: $mobileScreen) {
        background-color: $colorFooterResp;
        height: auto;
        padding-bottom: 1rem;
    }
}