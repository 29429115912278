.chatContainer {
    //cursor: pointer;
    position: relative;
    z-index: 201;
    //width: 3rem;
    //height: 15rem;
    //left: calc(100% - 10rem);
    //top: calc(100% - 10rem);


}    

.botIcon {
    background: transparent;
    width: 15rem;
}