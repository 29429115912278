%bContact {
    background: radial-gradient(50% 50% at 50% 50%, #4B4B4B 0%, rgba(0, 0, 0, 0.75) 100%);
    border-radius: 6px;
    width: 159px;
    height: 42px;
}

%flexEndCenter {
    display: flex;
    justify-content: flex-end;
    align-content: center;
}

%flexColumnCenter {
    display: flex;
    align-items: center;
    flex-direction: column;
}

%flexWrap {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

%mediaNone {
    @media screen and (max-width: $mobileScreen) {
        display: none;
    }
}

%fontTitle {
    font-weight: $fontWeightTitle;
    font-size: $fontSizeTitle;
}

%fontTitleSection {
    font-weight: $fontWeightTitle;
    font-size: $fontSizeTitleSection;
}

%hover110 {
    scale: 110%;
    transition: scale 0.5s;
}

%contactItem {
    width: 33%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

%imageBackground {
    background-image: url("../../img/fondo.webp");
    background-size: cover;
    background-attachment: fixed;
    min-height: 100vh;
    padding-top: 10rem;
}

%whiteGradient {
    background: linear-gradient(180deg, $colorWhite 0%, rgba(231, 231, 231, 0) 100%);
    margin-top: 5rem;
    padding-bottom: 2rem;
}

%sectionAnim {
    background-size: cover;
    background-position: center;
    height: 100vh;
    transition: height 3s;
}